import React from 'react'
import LoaderGif from '../../assets/images/gif/loader_gif.gif';

const Loader = () => {
  return (
    <>
      <div className='loader_wrapper'>
        <img src={LoaderGif} alt='loader' />
      </div>
    </>
  )
}

export default Loader