import React from 'react'
import HeartBeat from "../../assets/images/lottie/retreiving_result.json"
import Lottie from "lottie-react";

const FetchResult = () => {
  return (
    <div className="d-flex hk_resultLoader" style={{position: 'relative', zIndex: '99'}}>
      <Lottie animationData={HeartBeat} />
    </div>
  )
}

export default FetchResult