import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Close from "../../assets/images/svg/close_black.svg"
import SubscriptionModal from '../subscriptionModal/SubscriptionModal';
import parse from 'html-react-parser';


const scanResultStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: 'background.paper',
  boxShadow: '0px 4px 20px 0px rgba(15, 52, 90, 0.20)',
};

interface ScanResultModalProps {
  handlesetScanResultClose: () => void;
  scanResult: boolean;
  vitalsInfoModalData: any;
  handleUpdateCloseModal: () => void;
  openSubscribeModal: boolean;
  tryNowData: any;
  userData: any;
  redirectToScanTryNow: any;
  setOpenSubscribeModal: any;
  redirectToScan: any;
  setScanResult: any;
}

const ScanResultModal: React.FC<ScanResultModalProps> = ({ handlesetScanResultClose, scanResult, vitalsInfoModalData, handleUpdateCloseModal, openSubscribeModal, tryNowData, userData, redirectToScanTryNow, setOpenSubscribeModal, redirectToScan, setScanResult }) => {

  const redirectDoctor = () => {
    window.location.href = "https://merisehat.pk/doctor-now";
  }

  return (
    <>
      <Modal
        className="scanResult_modal"
        open={scanResult}
        onClose={handlesetScanResultClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={scanResultStyle} borderRadius={5}>
          <img src={Close} alt="" className='close' onClick={handlesetScanResultClose} />
          <div className='scanResult-bg'>
            <h5>{vitalsInfoModalData?.title}</h5>
            <h4>{vitalsInfoModalData?.value} <span>{vitalsInfoModalData?.unit}</span></h4>
            <h6 style={{ color: vitalsInfoModalData?.color }}>{vitalsInfoModalData?.tag}</h6>

            {vitalsInfoModalData?.tag?.toLowerCase() == "normal" ? (
              <>
                {tryNowData?.is_journey == 0 && (
                  <button onClick={(e) => redirectToScanTryNow()}>SCAN AGAIN</button>
                )}
                {tryNowData == true && (
                  <button onClick={(e) => {
                    if (userData?.subscription_recent == null) {
                      setOpenSubscribeModal(true);
                      setScanResult(false);
                    } else {
                      redirectToScan();
                    }
                  }}>SCAN AGAIN</button>
                )}
              </>
            ) : (
              <>
                <button onClick={() => redirectDoctor()}>CALL A DOCTOR</button>
              </>
            )}
          </div>
          <div className='scanResultPara'>
            <p>{vitalsInfoModalData?.description?.length > 0 ? parse(String(vitalsInfoModalData?.description?.[0])) : null}</p>
          </div>
        </Box>
      </Modal>
      <SubscriptionModal handleUpdateCloseModal={handleUpdateCloseModal} openSubscribeModal={openSubscribeModal} />
    </>
  )
}

export default ScanResultModal