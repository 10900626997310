import React, { useEffect, useState, useRef } from 'react';
import genderMale from '../../assets/images/svg/gender-male.svg';
import genderFemale from '../../assets/images/svg/gender-female.svg';
import genderOther from '../../assets/images/svg/gender-other.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import selectArrow from '../../assets/images/svg/dropdown-icon.svg';
import $ from 'jquery';
import { Form, Input, message, Select, Upload, Radio, Modal, Checkbox } from 'antd';
import { dateValidate } from '../../helpers/powerFunctions';
import API from '../../utils/httpService';
import Loader from '../loader/Loader';
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';


interface UpdateProfileModalProps {
  heightValues: any;
  weightValues: any;
  userData: any;
  citiesList: any;
  setOpenUpdate: any;

}

interface Values {
  name?: string;
  date?: string;
  month?: string;
  upload?: { originFileObj: File }[];
  year?: string;
  [key: string]: any;
}

function range(start: any, end: any) {
  return Array(end - start + 1)
    .fill(undefined)
    .map((_, idx) => start + idx);
}

const makingDate = range(1, 31);

const UpdateProfile: React.FC<UpdateProfileModalProps> = ({ weightValues, heightValues, userData, citiesList, setOpenUpdate }) => {

  var selectedItem: JQuery<HTMLElement>;
  const [userForm] = Form.useForm();
  const [valueFromWeight, setValueFromWeight] = useState<String>('');
  const [valueFromYear, setValueFromYear] = useState<String>('');
  const [valueDefaultWeight, setValueDefaultWeight] = useState<boolean>();
  const [valueDefaultYear, setValueDefaultYear] = useState<boolean>();
  const [changeForWeight, setChangeForWeight] = useState<boolean>(false);
  const [changeForYear, setChangeForYear] = useState<boolean>(false);
  const [valueDefaultHeight, setValueDefaultHeight] = useState<boolean>();
  const [changeForHeight, setChangeForHeight] = useState<boolean>(false);
  const [defaultAdd, setDefaultAdd] = useState<boolean>(true);
  const [defaultAddTwo, setDefaultAddTwo] = useState<boolean>(true);
  const [defaultAddThree, setDefaultAddThree] = useState<boolean>(true);
  const [changed, setChanged] = useState<boolean>(false);
  const [value3, setValue3] = useState<boolean>();
  const [gender, setGender] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imSmokerChecked, setImSmokerChecked] = useState<boolean>(false);
  const [imSmoker, setImSmoker] = useState<string>('');
  const [bpmChecked, setBpmChecked] = useState<boolean>(false);
  const [imBpm, setImBpm] = useState<string>('');
  const [diabeticStatus, setDiabeticStatus] = useState<string>('');
  const [locationUser, setLocationUser] = useState<any>({ lat: null, lon: null });
  const [city, setCity] = useState<string>('');
  const [extractedCity, setExtractedCity] = useState<any>('');
  const location = useLocation();
  const myParam = new URLSearchParams(location.search).get('isjourney');
  let customizedDate = userData?.birth_date?.split('-');
  let days = [];

  for (let i = 1; i < 32; i++) {
    days.push(i);
  }

  const newDate = new Date().getFullYear();
  const makinYear = range(newDate - 123, newDate - 0);
  const years = makinYear.map((y) => ({ value: y, label: y })).reverse();


  const handleChangeValue = (e: any) => {
    setValueFromWeight(`${e.activeIndex + 1}kg`);
    setValueDefaultWeight(false);
    setChangeForWeight(true);
  };

  const handleChangeValueTwo = (e: any) => {
    setChangeForHeight(true);
    setValueDefaultHeight(false);
  };

  const handleChangeValueYear = (e: any) => {
    setChangeForYear(true);
    setValueDefaultYear(false);
  };

  const handleChangeSmoker = (e: any) => {
    const checked = e.target.checked;
    setImSmokerChecked(checked);
    setImSmoker(checked ? 'yes' : 'no');
    setChanged(true);
  };

  const handleChangeBmi = (e: any) => {
    const checked = e.target.checked;
    setBpmChecked(checked);
    setImBpm(checked ? 'yes' : 'no');
    setChanged(true);
  };

  const handleChangeDiabetic = (e: any) => {
    setDiabeticStatus(e.target.value);
    setChanged(true);
  };

  useEffect(() => {
    if (defaultAdd) {
      const timeoutId = setTimeout(() => {
        const $activeItem = $('.weight_moby .swiper-slide.item.single_ite.active_Item').addClass('weight_moby active');
        const $container = $('div.single_ite_main');

        if ($activeItem && $container && $container.length) {
          const scrollLeft = $container.scrollLeft();
          if (typeof scrollLeft === 'number') {
            const $swiperWrapper = $container.find('.swiper-wrapper');
            if ($swiperWrapper.length) {
              const positionLeft = $activeItem.position()?.left || 0;
              const containerWidth = $container.width() || 0;
              const activeItemWidth = $activeItem.width() || 0;
              const scrollTo = scrollLeft - positionLeft + (containerWidth / 2) - (activeItemWidth / 2);
              $swiperWrapper.css('transform', `translateX(${scrollTo}px)`);
            }
          }
        }

        $activeItem?.removeClass('weight_moby active');
        selectedItem.addClass('weight_moby active');
      }, 3000);

      // Cleanup the timeout if defaultAdd changes or component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [defaultAdd]);

  useEffect(() => {
    if (defaultAddTwo) {
      const timeoutId = setTimeout(() => {
        const activeItem = $('.height_moby .swiper-slide.item.single_ite.active_Item');
        const selectedItem = activeItem.parent();
        const container = $('div.single_ite_main.height_moby');

        if (activeItem && selectedItem && container && container.length) {
          const scrollLeft = container.scrollLeft();
          if (typeof scrollLeft === 'number') {
            const positionLeft = activeItem.position()?.left || 0;
            const containerWidth = container.width() || 0;
            const activeItemWidth = activeItem.width() || 0;
            const scrollTo = scrollLeft - positionLeft + (containerWidth / 2) - (activeItemWidth / 2);
            container.find('.swiper-wrapper').css('transform', `translateX(${scrollTo}px)`);
          }
          activeItem.removeClass('height_moby active');
          selectedItem.addClass('height_moby active');
        }
      }, 3000);

      // Cleanup the timeout if defaultAddTwo changes or component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [defaultAddTwo]);

  useEffect(() => {
    if (defaultAddThree) {
      const timeoutId = setTimeout(() => {
        const activeItem = $('.single_ite_main.hk.year_moby .swiper-slide.item.single_ite.active_Item');
        const selectedItem = activeItem.parent();
        const container = $('div.single_ite_main.year_moby');

        if (activeItem && selectedItem && container && container.length) {
          const scrollLeft = container.scrollLeft();
          if (typeof scrollLeft === 'number') {
            const positionLeft = activeItem.position()?.left || 0;
            const containerWidth = container.width() || 0;
            const activeItemWidth = activeItem.width() || 0;
            const scrollTo = scrollLeft - positionLeft + (containerWidth / 2) - (activeItemWidth / 2);
            container.find('.swiper-wrapper').css('transform', `translateX(${scrollTo}px)`);
          }
          activeItem.removeClass('year_moby active');
          selectedItem.addClass('year_moby active');
        }
      }, 3000);

      // Cleanup the timeout if defaultAddTwo changes or component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [defaultAddThree]);

  useEffect(() => {
    setValueDefaultWeight(true);
    setValueDefaultYear(true);
    setValueDefaultHeight(true);
  }, []);

  useEffect(() => {
    if (changeForWeight) {
      setChanged(true);
    }
  }, [changed, changeForWeight]);

  useEffect(() => {
    if (changeForHeight) {
      setChanged(true);
    }
  }, [changed, changeForHeight]);

  useEffect(() => {
    if (changeForYear) {
      setChanged(true);
    }
  }, [changed, changeForYear]);

  useEffect(() => {
    if (userData?.phone) {
      const getMonth = (dateFormat: any) => {
        let [d, m, y] = dateFormat?.split(/\D/);
        const monthNew = new Date(y, m - 1, d);
        const monthValue = monthNew.toLocaleString('default', {
          month: 'short'
        });
        return monthValue;
      };
      const result = getMonth(
        `${userData?.birth_date?.split('-').reverse().join('/')}`
      );
      let heightFT = userData?.height?.split('.')[0];
      let heightIN = userData?.height?.split('.')[1];
      const dd = {
        value: userData?.birth_date?.split('-')[2],
        label: userData?.birth_date?.split('-')[2]
      };
      const mm = {
        value: userData?.birth_date?.split('-')[1],
        label: result
      };
      const yy = {
        value: userData?.birth_date?.split('-')[0],
        label: userData?.birth_date?.split('-')[0]
      };

      let number = userData?.phone?.split('').slice(2).join('');
      const images = [
        {
          uid: 1,
          name: 'my image',
          status: 'done',
          url: userData?.image,
          crossOrigin: 'anonymous'
        }
      ];

      // --------------------FOR +92 ============================??
      let codePhone = userData?.phone;
      codePhone = codePhone.substring(1, 11);
      let prefix = '+92';

      const newPhone = prefix + codePhone;

      // --------------------FOR +92 ============================??

      // --------------------FOR height ============================??

      // let newheight = user?.height;
      // let modifiedHeight = newheight?.slice(0, 3);

      // -------------------- ============================??
      if (userData?.diabetes) {
        setDiabeticStatus(userData.diabetes);
      }
      const formValues = {
        name: userData?.name && userData?.name,
        phone: newPhone,
        email: userData?.email && userData?.email,
        city_id: userData?.city_id,
        gender: userData?.gender ? userData?.gender : undefined,
        diabetes: userData?.diabetes ? userData?.diabetes : undefined,
        bloodpressuremedication: userData?.bloodpressuremedication ? userData?.bloodpressuremedication : undefined,
        smoking: userData?.smoking ? userData?.smoking : undefined,
        weight: !changeForWeight && userData?.weight ? userData?.weight : undefined,
        height: !changeForHeight && userData?.height ? userData?.height : undefined,
        date: dd.value,
        month: mm.value,
        year: yy.value ? yy.value : undefined,
        upload: userData?.image ? images : []
      };
      userForm.setFieldsValue(formValues);
    }
  }, [userData]);

  const onSubmit = async (values: Values) => {
    setChanged(false);
    const { date, month, year, city_id } = values;

    const checkDateFields = year === undefined && month === undefined && date === undefined;
    const validate = ""

    if (validate == "") {
      let payload: Record<string, any> = {};

      for (const key in values) {
        if (key !== 'date' && key !== 'month' && key !== 'year' && key !== 'upload') {
          // Check if the values is not undefined and not null
          if (values[key] !== undefined && values[key] !== null) {
            // Add the key-value pair to the new object
            payload[key] = values[key];
          }
        }
      }
      const yy = {
        value: userData?.birth_date?.split('-')[0],
        label: userData?.birth_date?.split('-')[0]
      };
      const desc_object = document.getElementsByClassName("swiper-slide-active");
      let newUserBirthDateAndMonth = userData?.birth_date?.split('-');
      if (changeForHeight && desc_object && desc_object[2] instanceof HTMLElement) {
        payload.height = desc_object[2].innerText
      }
      if (changeForWeight && desc_object && desc_object[1] instanceof HTMLElement) {
        payload.weight = desc_object[1].innerText
      }

      if (desc_object && desc_object[0] instanceof HTMLElement) {
        payload.birth_date = `${!changeForYear ? yy?.value : desc_object[0].innerText}-${newUserBirthDateAndMonth ? newUserBirthDateAndMonth[1] : '03'}-${newUserBirthDateAndMonth ? newUserBirthDateAndMonth[2] : '08'}`;
      }
      payload.city_id = extractedCity ? extractedCity?.id : 1;
      payload.gender = gender ? gender : userData?.gender;
      payload.smoking = imSmoker ? imSmoker : userData?.smoking;
      payload.bloodpressuremedication = imBpm ? imBpm : userData?.bloodpressuremedication;
      payload.diabetes = diabeticStatus ? diabeticStatus : userData?.diabetes;
      setLoading(true)
      try {
        const response = await API.post('/update-profile', payload);
        if (response?.status == 400) {
          swal("", `${response.data.message}`, "error");
          setLoading(false)
        }
        else if (response.status) {
          setLoading(false);
          setOpenUpdate(false);
          Cookies.remove('updateProfileModalShow');
          if (myParam && myParam == "trynow") {
            window.location.href = '/?isjourney=trynow';
          } else {
            window.location.href = '/?isjourney=startscan';
          }
        } else {
          // console.log(response?.data?.message, "sdsdsdsdsd")
          swal("", `${response.data.message || 'An error occurred'}`, "error");
          setLoading(false)
        }
      } catch (error) {
        // console.log(error, "errorerror")
        swal("", `${error}`, "error");
        setLoading(false)
      }
    } else {
      swal("", `Date Format is Invalid`, "error");
      setLoading(false)
    }
  };

  const handleGenderChange = (e: any) => {
    setGender(e.target.value); // Update selected gender state
    setChanged(true);
  };

  // const handleChangeForSelect = (value: any, label: any) => {
  //   if (value) {
  //     setChanged(true);
  //   }
  //   if (label === 'gender') {
  //     setValue3(value);
  //   }

  // };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const { latitude, longitude } = position.coords;
          setLocationUser({ lat: latitude, lon: longitude });
        },
        (error: GeolocationPositionError) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (locationUser.lat && locationUser.lon) {
      fetchCityName(locationUser.lat, locationUser.lon);
      const matchedCity = citiesList.find((item: any) => item.name.toLowerCase() === city.toLowerCase());
      if (matchedCity) {
        setExtractedCity(matchedCity);
      } else {
        console.log("City not found in citiesList");
      }
    }
  }, [locationUser, city]);

  const fetchCityName = async (lat: number, lon: number) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      const splitValue = data.address.city.split('Division')[0].trim();
      setCity(splitValue);
    } catch (error) {
      console.error("Error fetching city name:", error);
    }
  };


  return (
    <section className="updateProfile hk_customer_up">
      {loading && (
        <>
          <Loader />
        </>
      )}
      <>
        <div className='container'>
          <div className="d-lg-none">
            <Form
              form={userForm}
              onFinish={onSubmit}
              name="basic"
              initialValues={{ name: '' }}
              layout="vertical"
              className="udpate_profile_db_hk"
            >
              <div className=" row hk_form_up hk_mob_update_profile newDesignFonts">
                {/* <div className='col-12 pe-0'>
                  <p>City*</p>
                  <Form.Item
                    name="city_id"
                    className="c_select customer_date"
                  >
                    <Select
                      placeholder="Select City"
                      onChange={handleChangeForSelect}
                      suffixIcon={<img src={selectArrow} alt="arrow" />}
                    // value={userData?.city_id ? userData?.city_id : defaultValue}
                    >
                      {citiesList?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div> */}
                <div className='col-md-4'>
                  <div className="dateOfBirth customer_date_box">
                    <div className='row'>
                      <div className="height_and_weight_for_only_mob">
                        <div className="d-flex align-items-center justify-content-between urdu-right">
                          <div className='col-12'>
                            <p>Year of Birth</p>
                          </div>
                        </div>
                        <Form.Item
                          className="c_select"
                          name="weight"
                        >
                          <div className="single_ite_main hk year_moby">
                            <Swiper
                              slidesPerView={3}
                              onSlideChange={(e) => handleChangeValueYear(e)}
                              centeredSlides={true}
                            >
                              {years?.length > 0 &&
                                years?.map((year: any, index: any) => (
                                  <SwiperSlide
                                    className={`item single_ite ${valueDefaultYear && customizedDate && parseInt(customizedDate[0]) == year?.label
                                      ? 'active_Item'
                                      : ''
                                      }`}
                                    key={year?.value}
                                  >
                                    <label htmlFor={year?.value}>{year?.label}</label>
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <>
                      <div className="height_and_weight_for_only_mob">
                        <div className="d-flex align-items-center justify-content-between urdu-right">
                          <div className='col-12'>
                            <p>Weight</p>
                          </div>
                        </div>
                        <Form.Item
                          className="c_select"
                          name="weight"
                        >
                          <div className="single_ite_main weight_moby">
                            <Swiper
                              slidesPerView={3}
                              onSlideChange={(e) => handleChangeValue(e)}
                              centeredSlides={true}
                            >
                              {weightValues?.length > 0 &&
                                weightValues?.map((weight: any, index: any) => (
                                  <SwiperSlide
                                    className={`item single_ite ${valueDefaultWeight &&
                                      userData?.weight === weight
                                      ? 'active_Item'
                                      : ''
                                      }`}
                                    key={weight}
                                  >
                                    <label htmlFor={weight}>{weight}</label>
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                          </div>
                        </Form.Item>
                        <p>Height</p>
                        <Form.Item
                          className="c_select"
                          // rules={[
                          //   { required: true, message: i18n.t('field_required') }
                          // ]}
                          name="height"
                        >
                          <div className="single_ite_main height_moby">
                            <Swiper
                              slidesPerView={3}
                              onSlideChange={(e) => handleChangeValueTwo(e)}
                              centeredSlides={true}
                            >
                              {heightValues?.length > 0 &&
                                heightValues?.map((height: any, index: any) => (
                                  <SwiperSlide
                                    className={`item single_ite ${valueDefaultHeight &&
                                      userData?.height == height
                                      ? 'active_Item'
                                      : ''
                                      }`}
                                    key={height}
                                  >
                                    <label htmlFor={height}>{height}</label>
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                          </div>
                        </Form.Item>
                      </div>
                    </>
                  </div>
                </div>
                <div className='col-md-4'>
                  <p>Gender</p>
                  <Radio.Group
                    value={gender}
                    onChange={handleGenderChange}
                    optionType="button"
                    className="genderTabsOption d-flex align-items-center justify-content-between"
                  >
                    <Radio.Button value="male" className={userData && !gender && userData?.gender === "male" ? "activeGender" : "" || gender === "male" ? "activeGender" : ""}>
                      <img
                        src={genderMale}
                        alt="male icon"
                        className="img-fluid btn-icons-gender"
                      />
                      Male
                    </Radio.Button>
                    <Radio.Button value="female" className={userData && !gender && userData?.gender === "female" ? "activeGender" : "" || gender === "female" ? "activeGender" : ""}>
                      <img
                        src={genderFemale}
                        alt="female icon"
                        className="img-fluid btn-icons-gender"
                      />
                      Female
                    </Radio.Button>
                    <Radio.Button value="other" className={userData && !gender && userData?.gender === "other" ? "activeGender" : "" || gender === "other" ? "activeGender" : ""}>
                      <img
                        src={genderOther}
                        alt="other icon"
                        className="img-fluid btn-icons-gender"
                      />
                      Other
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div className="dateOfBirth customer_date_box mt-4 bmi_row">
                  <div className='row'>
                    <div className='col-12 pe-0 pb-4'>
                      <Checkbox
                        checked={!imSmoker && userData?.smoking ? userData?.smoking == "yes" : imSmokerChecked}
                        onChange={e => handleChangeSmoker(e)}>I am a smoker</Checkbox>
                    </div>
                    <div className='col-12 pe-0 pb-4'>
                      <Checkbox
                        checked={!imBpm && userData?.bloodpressuremedication ? userData?.bloodpressuremedication == "yes" : bpmChecked}
                        onChange={e => handleChangeBmi(e)}>I am on blood pressure medication</Checkbox>
                    </div>
                    <div className='col-12 pe-0 pb-4'>
                      <p className='diabet'>Are you diabetic?*</p>
                      <Radio.Group onChange={(e) => handleChangeDiabetic(e)} value={diabeticStatus} className='radio-diabet'>
                        <Radio value={"no"}>No</Radio>
                        <Radio value={"type1"}>Type 1</Radio>
                        <Radio value={"type2"}>Type 2</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="col-md-4">
                    <button
                      className={` schedule-appointment ${changed ? 'statechanged' : 'statenotchanged'
                        }`}
                      type="submit"
                      disabled={!changed}
                    >
                      CONFIRM DETAILS
                      {/* {i18n.t('save_changes')} */}
                      <span
                        className="schedule-icon-chevron"
                        style={{ height: '42px' }}
                      >
                        {/* <FiChevronRight /> */}
                      </span>
                    </button>
                  </div>
                </div>
              </div>

            </Form>
          </div>
        </div >
      </>
    </section >
  )
}

export default UpdateProfile