import React, { useEffect, useState } from 'react';
import LogoIcon from '../../assets/images/svg/logo.svg';
import LeftArrowIcon from '../../assets/images/svg/leftarrowIcon.svg';
import Close from "../../assets/images/svg/close_black.svg"
import InfoIcon from '../../assets/images/svg/info_black.svg';
import Button from '@mui/material/Button';
import InfoModal from '../infoModal/InfoModal';

interface SehatScan {
  userData: any;
  tryNowData: any;
  myParam: any;
  totalSeconds: string;
  toastShow: boolean;
  howToUseScreen: boolean;
  countDown: any
}

const SehatScan: React.FC<SehatScan> = ({ userData, tryNowData, totalSeconds, myParam, howToUseScreen, countDown, toastShow }) => {
  const [openSehat, setOpenSehat] = useState<boolean>(false);
  const handleSehatOpen = () => setOpenSehat(true);
  const handleSehatClose = () => setOpenSehat(false);


  return (
    <>
      <div className='pb-2 sehat-scan'>
        <div className='container px-0'>
          <div className="row">
            <div className="col-12 pe-1">
              <div className="align-items-center justify-content-between d-flex text-center position-relative">
                <>
                  <a href={`https://merisehat.pk/sehat-scan`}>
                    <img
                      src={LeftArrowIcon}
                      alt="Left arrow Icon"
                      className="img-fluid"
                    />
                  </a>

                  {/* close */}
                  {/* <a href="/">
                  <img
                    src={Close}
                    alt="Close arrow Icon"
                    className="img-fluid"
                  />
              </a> */}
                </>
                <div className="logo_only text-md-left text-center">
                  <a href="/">
                    <img
                      src={LogoIcon}
                      alt="Logo Icon"
                      className="img-fluid"
                    />
                  </a>
                </div>
                {!howToUseScreen && myParam && myParam == "trynow" && tryNowData !== true && toastShow && (
                  <div className='avail'>
                    <div className='rem'>
                      <p className='timer'>
                        {countDown}
                      </p>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
      <InfoModal handleSehatClose={handleSehatClose} openSehat={openSehat} />
    </>
  )
}

export default SehatScan;