import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Camera from "../../assets/images/svg/camera.svg"
import Glasses from "../../assets/images/svg/glasses.svg"
import Timer from "../../assets/images/svg/timer.svg"

const infoStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 20px 0px rgba(15, 52, 90, 0.20)',
};

interface InfoModalProps {
    handleSehatClose: () => void;
    openSehat: boolean;
}

const InfoModal: React.FC<InfoModalProps> = ({handleSehatClose, openSehat}) => {
    return (
      <Modal
          open={openSehat} // Change openSehat to open
          onClose={handleSehatClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box sx={infoStyle} borderRadius={5} style={{ padding: 30  }}>
              <Typography id="modal-modal-description" sx={{ mb: 3 }} align="center">
                <img src={Camera} alt="" />
                <p className="mt-3">Face the camera and position your face within the circle throughout the measurement</p>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: 3 }} align="center">
                <img src={Glasses} alt="" />
                <p className="mt-3">Remove any accessories such as glasses and hats, including any makeup products on your face before measuring.</p>
              </Typography>
              <Typography id="modal-modal-description" align="center">
                <img src={Timer} alt="" />
                <p className="mt-3">face the camera and position your face within the circle throughout the measurement</p>
              </Typography>
          </Box>
      </Modal>
    )
  }

export default InfoModal