import React, { useState } from 'react';
import LeftArrowIcon from '../../assets/images/svg/leftarrowIcon.svg';
import shareIcon from '../../assets/images/svg/share_black.svg';
import Button from '@mui/material/Button';
// import ScanResultModal from '../scanResultModal/ScanResultModal';
import { ShareSocial } from 'react-share-social';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import closeIcon from '../../assets/images/svg/close_black.svg';

const ScanResults: React.FC = () => {
    const [scanResult, setScanResult] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const handleScanResultOpen = () => setScanResult(true);
    const handlesetScanResultClose = () => setScanResult(false);

    return (
        <div className="pb-2 px-3 sehat-scan">
            <div className="container px-0">
                <div className="align-items-center justify-content-between d-flex text-center position-relative">
                    <a href="/">
                        <img
                            src={LeftArrowIcon}
                            alt="Left arrow Icon"
                            className="img-fluid"
                        />
                    </a>
                    <div className="logo_only text-md-left text-center">
                        <h5>Scan Results</h5>
                    </div>
                    <Button
                        onClick={() => setOpen(true)}
                        className="justify-content-end scanResult"
                        style={{ padding: 0 }}
                    >
                        <img
                            src={shareIcon}
                            alt="share Icon"
                            className="img-fluid"
                        />
                    </Button>
                </div>
            </div>
            <BottomSheet className="hk_share" open={open}>
                <div className="closeIcon">
                    <img src={closeIcon} alt="close" onClick={() => setOpen(false)} />
                </div>
                <ShareSocial
                    url={window.location.href}
                    socialTypes={['facebook', 'linkedin']}
                />
            </BottomSheet>
            {/* <ScanResultModal handlesetScanResultClose={handlesetScanResultClose} scanResult={scanResult} /> */}
        </div>
    );
};

export default ScanResults;
