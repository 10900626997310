import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import crypto from 'crypto';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { publicKey } from './publikcKey';
import SehatScan from '../../component/sehat-scan/SehatScan';
import ScanMask from '../../assets/images/gif/scan_gif_updated.gif';
import Heart from '../../assets/images/svg/heart.svg';
import UpdateProfileModal from '../../component/updateProfile/UpdateProfileModal';
import API from '../../utils/httpService';
import SubscriptionModal from '../../component/subscriptionModal/SubscriptionModal';
import PleaseSubscribe from '../../component/pleaseSubscribe/PleaseSubscribe';
import { useLocation } from 'react-router-dom';
import ScanPackage from '../../component/scanPackage/ScanPackage';
import Cookies from 'js-cookie';
import UpdateProfile from '../../component/updateProfile/UpdateProfile';


export const Profile: FC = () => {
  const [age, setAge] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [weight, setWeight] = useState<number>();
  const [gender, setGender] = useState<string>('');
  const [smoke, setSmoke] = useState<string>('');
  const [medication, setMedication] = useState<string>('');
  const [diabetic, setDiabetic] = useState<string>('');
  const [listenerForAutoSubmit, setListenerForAutoSubmit] = useState(false);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [openSubscribeModal, setOpenSubscribeModal] = useState<boolean>(false);
  const [toastShow, setToastShow] = useState<boolean>(false);
  const [heightValues, setHeightValues] = useState<number>();
  const [weightValues, setWeightValues] = useState<number>();
  const [citiesList, setCitiesList] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [tryNowData, setTryNowData] = useState<any>();
  const [totalSeconds, setTotalSeconds] = useState<string>("");
  const [pleaseSubs, setPleaseSubs] = useState<boolean>(false);
  const [pleaseSubsData, setPleaseSubsData] = useState<string>("");
  const [tryNow, setTryNow] = useState<boolean>();
  const [countDown, setCountDown] = useState<string>('00:00');
  const location = useLocation();
  const [howToUseScreen, setHowToUseScreen] = useState<boolean>(false);

  const myParam = new URLSearchParams(location.search).get('isjourney');

  const handleUpdateOpen = () => setOpenUpdate(true);

  useEffect(() => {
    Cookies.remove('resultDataUserId')
  }, [])


  const handleUpdateClose = () => {
    setOpenUpdate(false);
    Cookies.remove('updateProfileModalShow');
  }

  const handleUpdateCloseModal = () => setOpenSubscribeModal(false);

  useEffect(() => {
    if (myParam == null || !myParam) {
      window.location.href = "https://merisehat.pk/";
    }
  }, []);

  useEffect(() => {
    if (Cookies.get("updateProfileModalShow") && Cookies.get("updateProfileModalShow") == "1") {
      handleUpdateOpen();
    }
  }, [howToUseScreen])

  useEffect(() => {
    if (!totalSeconds || !/^\d{2}:\d{2}$/.test(totalSeconds)) {
      console.error('Invalid totalSeconds format');
      return;
    }

    const [hours, minutes] = totalSeconds.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      console.error('Invalid totalSeconds values');
      return;
    }

    let timer = (hours * 3600) + (minutes * 60);

    const interval = setInterval(() => {
      const currentHours = Math.floor(timer / 3600);
      const currentMinutes = Math.floor((timer % 3600) / 60);

      const formattedHours = currentHours < 10 ? '0' + currentHours : currentHours.toString();
      const formattedMinutes = currentMinutes < 10 ? '0' + currentMinutes : currentMinutes.toString();

      setCountDown(`${formattedHours}:${formattedMinutes}`);

      if (--timer < 0) {
        clearInterval(interval);
        setCountDown('00:00');
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [totalSeconds]);

  const setWeightAndHeight = async () => {
    const res = await API.get(`/get-weight-height`)
    if (res?.status) {
      setHeightValues(res?.data?.height);
      setWeightValues(res?.data?.weight);
    }
  }

  const getCities = async () => {
    const res = await API.get(`/cities`)
    if (res?.status) {
      setCitiesList(res?.data);
    }
  }

  const getUserData = async () => {
    const res = await API.get(`/user`);
    if (res?.status) {
      setUserData(res?.data?.user);
    }
  }


  const tryNowJourney = async () => {
    const res = await API.get(`/try-now`);
    if (res?.status) {
      setTryNowData(res?.data);
      setTotalSeconds(res?.data?.remaining_time);
    }
    else {
      window.location.href = "https://merisehat.pk/sehat-scan";
      setTryNowData(true);
    }
  }

  const healthScanCheck = async () => {
    try {
      const res = await API.post(`/health-scan/check`);
      if (res?.status == 400) {
        setPleaseSubsData(res?.data?.message);
        setPleaseSubs(true);
      }
    } catch (e) {
      console.log(e, "error")
    }

  }

  useEffect(() => {
    setWeightAndHeight();
    getUserData();
    getCities();
  }, [])

  useEffect(() => {
    if (!howToUseScreen) {
      setToastShow(true)
    }
  }, [howToUseScreen])

  useEffect(() => {
    if (myParam && myParam == "trynow") {
      setTryNow(true);
    } else {
      setTryNow(false);
    }
  }, [myParam])

  useEffect(() => {
    if (tryNow == false) {
      healthScanCheck();
    } else if (tryNow == true) {
      tryNowJourney();
    }
  }, [tryNow])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (userData) {
      if (userData?.height) {
        const feet = userData?.height.split("ft");
        const inches = feet[1].split("in");
        var cmTotal: number = parseInt(feet) * 30.48 + parseInt(inches) * 2.54;
        if ((cmTotal > 12 && cmTotal < 121)) {
          setHeight(Math.ceil(cmTotal));
        }
      }
      if (userData?.weight) {
        let userWeight = parseInt(userData?.weight);
        if ((userWeight > 29 && userWeight < 301)) {
          setWeight(parseInt(userData?.weight));
        }
      }
      if ((userData?.age < 13 && userData?.age > 120)) {
        setAge(userData?.age);
      }
      setGender(userData?.gender);
      setSmoke(userData?.smoking);
      setMedication(userData?.bloodpressuremedication);
      setDiabetic(userData?.diabetes);
      if (Cookies.get('userFirstTime') == undefined && userData?.is_first_health_scan == true) {
        setHowToUseScreen(true);
      }
      else if (Cookies.get('userFirstTime') !== undefined && userData?.is_first_health_scan == true) {
        setHowToUseScreen(false);
        Cookies.remove('updateProfileModalShow');
      }
      else {
        Cookies.remove('updateProfileModalShow');
      }
    }
  }, [JSON.stringify(userData)])

  const onSubmit = async (data: any) => {

    // const { age, diabetic, height, medication, gender, smoker, weight } = data;

    // Get the token ID
    const { data: configResponseData } = await axios.get('/api/configId');


    // Get a token from the back end
    const { data: tokenResponseData } = await axios.post('/api/token');
    const { Token, RefreshToken } = tokenResponseData;

    const payload = {
      identifier: uuidv4(), // Unique identifier for the user, it will be used to tag the measurement
      age: age,
      height: height,
      weight: weight,
      gender: gender,
      // smoking: '1',
      // bloodpressuremedication: '1',
      // diabetes: diabetic,
    };


    const buffer = Buffer.from(JSON.stringify(payload));
    const encryptedProfile = crypto.publicEncrypt(publicKey, buffer) || null;
    const sessionId = uuidv4(); // Unique identifier for the session, passed back as part of the result object to identify the measurement

    const sessionIdValue = !!sessionId ? sessionId : 'undefined';
    window.location.href = `https://scan.merisehat.pk/c/${configResponseData.configId
      }/${encodeURIComponent(encryptedProfile.toString('base64'))}/${encodeURIComponent(
        Token
      )}/${encodeURIComponent(RefreshToken)}/${sessionIdValue}`;
  };

  useEffect(() => {
    if (age && height && weight && gender && smoke && medication && diabetic) {
      setListenerForAutoSubmit(true);
    }
  }, [age, height, weight, gender, smoke, medication, diabetic]);

  const handleStartScan = async (data: any) => {
    handleSubmit(onSubmit)();
  }

  const handleRedirectHome = () => {
    if (myParam && myParam == "trynow") {
      window.location.href = "/home?isjourney=trynow"
    } else {
      window.location.href = "/home?isjourney=startscan"
    }
  }

  const UpdateProfileModalShow = () => {
    Cookies.set('updateProfileModalShow', '1')
    Cookies.set('userFirstTime', "1");
    setHowToUseScreen(false);
  }


  return (
    <>
      {userData && !howToUseScreen && (
        <>
          <section className='profile d-md-none'>
            <SehatScan countDown={countDown} howToUseScreen={howToUseScreen} toastShow={toastShow} myParam={myParam} totalSeconds={totalSeconds} tryNowData={tryNowData} userData={userData} />
            {/* video */}
            {!howToUseScreen && myParam && myParam == "trynow" && tryNowData !== true && toastShow && (
              <div className='avail hk_outside'>
                <p>You can avail {tryNowData?.healthCount}  scans in the next 24 hours</p>
              </div>
            )}
            <div className='video-wrap'>
              <div className="VideoAndStatsWrapper">
                <div className={`VideoWrapper`}>
                  <img src={ScanMask} className='scanmark' />
                </div>
              </div>
              {/* video */}
              <h5 className='better-results'>For better results please input your height and weight</h5>
              <Button onClick={handleUpdateOpen} className="mb-3 update-profile-btn" type="submit">
                <span className="fs-16">Update Info</span>
              </Button>
              <hr />
              <div className='wrapper_btn'>
                <button className='start-scan' onClick={(e) => tryNowData?.is_journey !== 0 && userData?.subscription_recent == null ? setOpenSubscribeModal(true) : handleStartScan(e)}><span className={`${tryNowData?.is_journey == 0 ? 'btn_text_wrapper' : ''}`}>Start Scan</span>
                  {tryNowData?.is_journey == 0 && (
                    <span className='ticker'>{tryNowData?.free_health_scan - tryNowData?.healthCount} of 3</span>
                  )}
                </button>
              </div>
              <span className='learn_more'><a onClick={() => handleRedirectHome()} >Learn More</a></span>
            </div>
          </section>
          <UpdateProfileModal setOpenUpdate={setOpenUpdate} citiesList={citiesList} userData={userData} handleUpdateClose={handleUpdateClose} openUpdate={openUpdate} weightValues={weightValues} heightValues={heightValues} />
          <SubscriptionModal handleUpdateCloseModal={handleUpdateCloseModal} openSubscribeModal={openSubscribeModal} />
          <PleaseSubscribe pleaseSubsData={pleaseSubsData} pleaseSubs={pleaseSubs} />

        </>
      )}

      {/* package detail */}
      {userData && howToUseScreen && (
        <section className='package-detail d-md-none'>
          <ScanPackage />
          <div className='video-player'>
            <div className='video-play'>
              <div className="video-container">
                <iframe
                  title="video"
                  width="100%"
                  height="190px"
                  src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <ul>
              <li><span className='count'>1</span><p>Please enter your correct age, weight and height</p></li>
              <li><span className='count'>2</span><p>Scan in a well lit room with no bright light behind you</p></li>
              <li><span className='count'>3</span><p>During scan, avoid unnecessary movement and keep your phone steady</p></li>
            </ul>
            <div className='continue'>
              <a className='btn call' onClick={() => UpdateProfileModalShow()}>continue</a>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
