import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';

interface SubscriptionModal {
    handleUpdateCloseModal: () => void;
    openSubscribeModal: boolean;
}
const SubscriptionModal: React.FC<SubscriptionModal> = ({ handleUpdateCloseModal, openSubscribeModal }) => {

    return (
        <>
            <Modal
                open={openSubscribeModal}
                onCancel={handleUpdateCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='subscribeModalMains'
            >
                <div className='subscribeModal'>
                    <h2>Subscribe Now</h2>
                    <p>You have availed your free demo.  Please subscribe to our plan for more vital scans.</p>
                    <a href='https://merisehat.pk/pricing' className='btnPlan'>go to Plans</a>
                </div>
            </Modal>
        </>
    )
}

export default SubscriptionModal