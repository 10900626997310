import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';

interface SubscriptionModal {
    handleResultCloseModal: () => void;
    openErrorModal: boolean;
    userData: any;
    tryNowData: any;
}
const ResultErrorModal: React.FC<SubscriptionModal> = ({ handleResultCloseModal, openErrorModal, userData, tryNowData }) => {

    return (
        <>
            <Modal
                open={openErrorModal}
                onCancel={handleResultCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='subscribeModalMains'
            >
                <div className='subscribeModal'>
                    <h2>Error</h2>
                    <p>An error occurred during the scan. Please try again.</p>
                    <a href={`${tryNowData?.is_journey == 0 && userData?.subscription_recent == null ? '/?isjourney=trynow' : '/?isjourney=startscan'}`} className='btnPlan'>Re scan</a>
                </div>
            </Modal>
        </>
    )
}
export default ResultErrorModal