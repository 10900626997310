import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { baseURLStaging } from '../common/config';

// Create an Axios instance
const API = axios.create({
    baseURL: `${baseURLStaging}`,
    timeout: 60000
});

// Request interceptor
API.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        try {
            const token = Cookies.get('Authorization');
            const lang = Cookies.get('lang');

            // Ensure headers are initialized
            if (!config.headers) {
                config.headers = {};
            }
            if (token) {
                config.headers['Authorization'] = token;
            }
            config.headers['locale'] = "1";
            config.headers['platform'] = "web";
        } catch (error) {
            console.error('Error setting headers:', error);
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

// Response interceptor
API.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data
    },
    async (error: AxiosError) => {
        const { response } = error;
        if (response?.status === 403 || response?.status === 401) {
            const Authorization = Cookies.get('Authorization');
            // toast.error('Login expired, redirecting...');
            if (Authorization) {
                Cookies.remove('Authorization');
                Cookies.remove("Authorization", { domain: "merisehat.pk" });
                Cookies.remove("Authorization", { domain: ".merisehat.pk" });
            }
            setTimeout(() => {
                // window.location.href = "https://merisehat.pk/phone-number";
            }, 5000);
        }
        if (error.message === 'Network Error') {
            // toast.error(error.message);
        }
        if (response?.config.url == '/health-scan/check' || response?.config.url == "/update-profile") {
            const apiResponse = response ? response : Promise.reject(error);
            return apiResponse;

        } else {
            const apiResponse = response ? response.data : Promise.reject(error);
            return apiResponse;

        }
    }
);

export default API;
