import React from 'react'
import LeftArrowIcon from '../../assets/images/svg/leftarrowIcon.svg';

const ScanPackage = () => {
  return (
    <div className='pb-2 package-scan'>
      <div className='container px-0'>
        <div className="align-items-center d-flex text-center position-relative">
          <>
            <a href="https://merisehat.pk/sehat-scan">
              <img
                src={LeftArrowIcon}
                alt="Left arrow Icon"
                className="img-fluid"
              />
            </a>
          </>
          <div className="text-md-left text-center">
            <h6>How to use our scan technology</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScanPackage