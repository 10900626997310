import React, { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Profile, Results, Home } from '../Pages';
import logo from '../assets/images/logo_acme.png';
import { Box } from '@mui/system';
import "../assets/css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export const App: FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#c50059',
      },
      secondary: {
        main: '#00c56c',
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container component="main" maxWidth="sm" sx={{ mb: 3, mt: 3 }}>
          {/* <Box p="5" mt="10">
          <img src={logo} />
        </Box> */}
          <Router>
            <Switch>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/results">
                <Results />
              </Route>
              <Route path="/">
                <Profile />
              </Route>
            </Switch>
          </Router>
        </Container>
      </ThemeProvider>
    </>
   
  );
};
