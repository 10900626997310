import React, { FC } from 'react';
import './home.css'
import Carousel from 'react-bootstrap/Carousel';
import ImgSlide1 from '../../assets/images/svg/img_slide1.svg'
import ImgSlide2 from '../../assets/images/svg/img_slide2.svg'
import ImgSlide3 from '../../assets/images/svg/img_slide3.svg'
import HeartBeat from '../../assets/images/svg/heart_beat.svg'
import BloodPressure from '../../assets/images/svg/blood_pressure.svg'
import StressLevel from '../../assets/images/svg/stress_level.svg'
import Rate from '../../assets/images/svg/rate.svg'
import CameraCalibration from '../../assets/images/svg/camera_calibration.svg'
import detection from '../../assets/images/svg/roi_detection.svg'
import extraction from '../../assets/images/svg/rbg_extraction.svg'
import VitalSignCalculation from '../../assets/images/svg/vital_sign_calculation.svg'
import CrossBlack from '../../assets/images/svg/cross_black.svg'
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';


export const Home: FC = () => {
  const location = useLocation();
  const myParam = new URLSearchParams(location.search).get('isjourney');


  return (
    <div className='home-page d-md-none'>
      <a href={`${myParam && myParam == "trynow" ? '/?isjourney=trynow' : '/?isjourney=startscan'}`} className='crossImg'><img src={CrossBlack} /></a>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active slide">
            <h3>How to Scan?</h3>
            <img src={ImgSlide1} alt='img silde 1' />
            <p>Keep as still as possible and look into the front camera for 30 seconds</p>
          </div>
          <div className="carousel-item slide">
            <h3>Light & Position</h3>
            <img src={ImgSlide2} alt='img silde 2' />
            <p>Make sure there is no bright light behind you.</p>
          </div>
          <div className="carousel-item slide">
            <h3>Accessories & Makeup</h3>
            <img src={ImgSlide3} alt='img silde 3' />
            <p>Remove accessories such as glasses and hats, including any makeup products on your face before measuring.</p>
          </div>
          <div className="carousel-item slider">
            <h3>What are we measuring</h3>
            <p className='short-para'>Our Artificial Intelligence technology runs on your phone and enables you to perform scans to accurately measure the following vitals:</p>
            <div className='row mx-0 my-4'>
              <div className='col-3'>
                <div className='listing'>
                  <img src={HeartBeat} />
                  <p>Heart Rate</p>
                </div>
              </div>
              <div className='col-3'>
                <div className='listing'>
                  <img src={BloodPressure} />
                  <p>Stress Levels</p>
                </div>
              </div>
              <div className='col-3'>
                <div className='listing'>
                  <img src={StressLevel} />
                  <p>Blood Pressure</p>
                </div>
              </div>
              <div className='col-3'>
                <div className='listing'>
                  <img src={Rate} />
                  <p>Respiration Rate</p>
                </div>
              </div>
            </div>
            <hr />
            <h4>How we are measuring</h4>
            <ul>
              <li>
                <div className='d-flex align-items-center'>
                  <div className='number'>
                    <h6>1</h6>
                    <img src={CameraCalibration} />
                  </div>
                  <div className='categ'>
                    <h5>Camera Calibration</h5>
                    <p>The camera adjusts the video exposure and optimizes the video quality</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex align-items-center'>
                  <div className='number'>
                    <h6>2</h6>
                    <img src={detection} />
                  </div>
                  <div className='categ'>
                    <h5>ROI Detection</h5>
                    <p>Region of interest is identified and cropped</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex align-items-center'>
                  <div className='number'>
                    <h6>3</h6>
                    <img src={extraction} />
                  </div>
                  <div className='categ'>
                    <h5>RGB Extraction</h5>
                    <p>Cropped video is spliced into Red, Green and Blue (RGB) values.</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex align-items-center'>
                  <div className='number'>
                    <h6>4</h6>
                    <img src={VitalSignCalculation} />
                  </div>
                  <div className='categ'>
                    <h5>Vital Sign Calculation</h5>
                    <p>The resulting data from the RGB values is analyzed using our algorithm. The results are produced within 60 seconds.</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='better-result'>
        <p>For better results please input your correct height and weight</p>
        <a href={`${myParam && myParam == "trynow" ? '/?isjourney=trynow' : '/?isjourney=startscan'}`}>Update</a>
      </div>
    </div >
  )
}