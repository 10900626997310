export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApJq44P2IfLRe8O1CQfK/
Ww2xAw5B13i1GZ/r6X2cl+N47qO7LRsndpjQxRF5Twq5rjsaEUBc/P/qglqg031Q
cYqSdwEkv8NF+i5oxN1vHuPB+rBaAwMSXagouSNCyXss/a8u6v9bQQE+4mX5Ozzx
eqeZhSpBaYk00/WJe4r0l47arzAopSCj2zrQimARGQAE9TQRnWRnqTk7deCAWkMR
1jxltGMFPR3Vz26j2WN5nG5fArSUi4UtYJVbM8vClq+ezOJErzQZ4Ig1YKKK7yb6
sOLFBvnRardLQ9fAo0rc/VzOheR26BQPNDYJDwiIrc8NVYcndLBBJ90GDBzPBC+s
+QIDAQAB
-----END PUBLIC KEY-----
`;
