import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';

interface SubscriptionModal {
    pleaseSubs: boolean;
    pleaseSubsData: string;
}
const PleaseSubscribe: React.FC<SubscriptionModal> = ({ pleaseSubs, pleaseSubsData }) => {

    return (
        <>
            <Modal
                open={pleaseSubs}
                // onCancel={handleUpdateCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='subscribeModalMains pleaseSubs'
            >
                <div className='subscribeModal'>
                    <h2>{pleaseSubsData}</h2>
                    <p>To get unlimited scans and free video consults with doctors please view our subscription packages below</p>
                    <a href='https://merisehat.pk/pricing' className='btnPlan'>VIEW PACKAGES</a>
                </div>
            </Modal>
        </>
    )
}

export default PleaseSubscribe