import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal'
import UpdateProfile from './UpdateProfile';
import Close from "../../assets/images/svg/close_black.svg"

const updateStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 340,
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 20px 0px rgba(15, 52, 90, 0.20)',
};

interface UpdateProfileProps {
    handleUpdateClose: () => void;
    openUpdate: boolean;
    heightValues: any;
    weightValues: any;
    userData: any;
    citiesList: any;
    setOpenUpdate: any;
}

const UpdateProfileModal: React.FC<UpdateProfileProps> = ({ handleUpdateClose, openUpdate, heightValues, weightValues, userData, citiesList, setOpenUpdate }) => {
    return (
        <>
            <Modal
                open={openUpdate}
                onClose={handleUpdateClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='updateprofile'
            >
                <Box sx={updateStyle} borderRadius={5}>
                    <img src={Close} alt="" className='close' onClick={handleUpdateClose} />
                    <div className="lab-modal-body">
                        <div className="instructionModals">
                            <h4 className="fs-24 line-height-30 text-center">Update Profile</h4>
                        </div>
                        <UpdateProfile setOpenUpdate={setOpenUpdate} citiesList={citiesList} userData={userData} weightValues={weightValues} heightValues={heightValues} />
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default UpdateProfileModal